<template>
  <div class="ud-body">
    <a-card :bordered="false">
      <!-- 搜索表单 -->
      <a-form
        ref="form"
        name="aForm"
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="20" :md="24" :sm="24" :xs="24">
            <a-form-item label="搜索字段:" :labelCol="{ span: 1.5, offset: 0 }">
              <a-select
                mode="multiple"
                v-model:value="addField"
                @change="fieldChange"
                placeholder="请选择搜索字段"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in fieldList"
                  :key="index"
                  :value="index"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="4" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="info" @click="openFieldList" v-if="owner">设置</a-button>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col
            :lg="6"
            :md="12"
            :sm="24"
            :xs="24"
            v-for="item in customField"
            :key="item.fieldId"
          >
            <a-form-item :label="item.label">
              <a-input
                v-if="item.type === '单行文本'"
                v-model:value.trim="where[item.fieldId]"
                placeholder="请输入"
                allow-clear
              />
              <a-input
                v-if="item.type === '手机号'"
                v-model:value.trim="where[item.fieldId]"
                placeholder="请输入手机号"
                allow-clear
              />
              <a-select
                v-if="item.type === '下拉框'"
                v-model:value="where[item.fieldId]"
                placeholder="请选择"
                @change="hh"
                allow-clear
              >
                <a-select-option
                  v-for="(value, index) in strToArr(item)"
                  :key="index"
                  :value="value"
                  >{{ value }}</a-select-option
                >
              </a-select>
              <a-radio-group
                v-if="item.type === '单项选择'"
                v-model:value="where[item.fieldId]"
              >
                <a-row>
                  <a-col
                    :span="12"
                    v-for="(value, index) in strToArr(item)"
                    :key="index"
                  >
                    <a-radio :value="value">{{ value }}</a-radio>
                  </a-col>
                </a-row>
              </a-radio-group>
              <a-checkbox-group
                v-if="item.type === '多项选择'"
                v-model:value="where[item.fieldId]"
              >
                <a-row>
                  <a-col
                    :span="12"
                    v-for="(value, index) in strToArr(item)"
                    :key="index"
                  >
                    <a-checkbox :value="value">{{ value }}</a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
              <a-input-number
                v-if="item.type === '数字'"
                v-model:value="where[item.fieldId]"
                placeholder="请输入"
                allow-clear
              ></a-input-number>
              <a-textarea
                v-if="item.type === '多行文本'"
                allow-clear
                v-model:value="where[item.fieldId]"
                placeholder="请输入"
              ></a-textarea>
              <a-date-picker
                v-if="item.type === '日期'"
                v-model:value="where[item.fieldId]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="tableMember"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        :where="where"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @columnsChange="columnschange()"
      >
        <template #toolbar>
          <a-space v-if="owner">
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
            <a-button @click="showImport = true">
              <template #icon>
                <upload-outlined />
              </template>
              <span>导入</span>
            </a-button>
            <a-button @click="showExport = true">
              <template #icon>
                <export-outlined />
              </template>
              <span>导出</span>
            </a-button>
          </a-space>
        </template>
        <template #nickname="{ record }">
          <router-link :to="'/system/user/info?id=' + record.userId">
            {{ record.nickname }}
          </router-link>
        </template>
        <template #roles="{ record }">
          <a-tag
            v-for="(item, index) in record.roles"
            :key="index"
            color="blue"
          >
            {{ item.roleName }}
          </a-tag>
        </template>
        <template #img="{ text }">
          <div class="image" v-if="text">
            <a-image
              :src="text + '?width=120'"
              :width="120"
              :height="90"
            ></a-image>
          </div>
        </template>
        <template #enable="{ text, record }">
          <a-switch
            :checked="text === 0"
            @change="(checked) => editEnabled(checked, record)"
          />
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a-divider type="vertical" />
            <a
              @click="openEdit(record)"
              v-if="owner || record.userId === userInfo.userId"
              >修改</a
            >
            <a-divider type="vertical"  v-if="owner" />
            <a-popconfirm
              title="确定要删除此用户吗？"
              @confirm="remove(record)"
            >
              <a class="ud-text-danger" v-if="owner">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
  </div>
  <!-- 编辑弹窗 -->
  <!-- <user-edit
    v-model:visible="showEdit"
    :corpId="corpId"
    :data="current"
    @done="importDone"
  /> -->
  <!-- 导入弹窗 -->
  <user-import
    v-model:visible="showImport"
    :corpId="corpId"
    @done="importDone"
  />
  <!-- 导出弹窗 -->
  <user-export
    v-model:visible="showExport"
    :corpId="corpId"
    :where="where"
    @done="reload"
  />
  <!-- 模板字段设置 -->
  <a-modal
    :width="1300"
    v-model:visible="showFieldList"
    :confirm-loading="loading"
    @ok="saveTemplate"
    @cancel="handleCancel"
  >
    <fromFieldList
      ref="corpField"
      :formId="formId"
      :corpId="corpId"
      @done="importDone"
    />
  </a-modal>
  <!-- 表单新增修改 -->
  <form-preview
    :isPreview="false"
    v-model:visible="showEdit"
    :corpId="corpId"
    :data="current"
    @done="importDone"
  ></form-preview>

  <!-- 详情弹窗 -->
  <user-detail
    :isPreview="false"
    v-model:visible="showDetail"
    :corpId="corpId"
    :data="current"
    @done="importDone"
  ></user-detail>
</template>
<script>
import * as corpUserApi from '@/api/corp/corpUser.js'
import * as corpApi from '@/api/corp/corp.js'
import formPreview from './formPreview.vue'
import fromFieldList from './field.vue'
import { createVNode } from 'vue'
import {
  PlusOutlined,
  // MinusCircleOutlined,
  ExportOutlined,
  DeleteOutlined,
  UploadOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
// import UserEdit from './index-edit'
import UserImport from './index-import'
import UserExport from './index-export'
import userDetail from './index-detail.vue'
export default {
  name: 'CorpUser',
  props: {
    // 是否打开弹窗
    corpId: Number
  },
  components: {
    PlusOutlined,
    DeleteOutlined,
    UploadOutlined,
    ExportOutlined,
    UserImport,
    UserExport,
    // UserEdit,
    formPreview,
    fromFieldList,
    userDetail
  },
  data() {
    return {
      owner: false,
      userInfo: this.$store.state.user.user,
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.tableMember.tableIndex + index
        },
        {
          title: '姓名',
          dataIndex: 'nickname',
          sorter: true
        },
        {
          title: '性别',
          dataIndex: 'sex',
          sorter: true
        },
        {
          title: '手机号',
          dataIndex: 'username',
          sorter: true
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 是否显示用户导入弹窗
      showImport: false,
      // 是否显示导出字段
      showExport: false,
      formColums: [],
      fieldList: [], // 表单字段列表下拉框选择
      customField: [
        {
          fieldId: 'frmA',
          label: '序号',
          type: 'text'
        }
      ],
      defaultField: [],
      formId: 0,
      showFieldList: false, // 设置字段显示隐藏
      addField: []
    }
  },
  watch: {
    async corpId() {
      if (this.corpId) {
        await this.getCorpInfo()
        await this.queryFormField()
        this.reload()
      }
    }
  },
  async mounted() {
    if (this.corpId) {
      await this.getCorpInfo()
    }
    await this.queryFormField()
  },
  methods: {
    getCorpInfo() {
      corpApi
        .getCorpInfo(this.corpId)
        .then((res) => {
          if (res.code === 0) {
            this.owner = res.data.owner
            this.userId = res.data.userId
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 保存模板数据
    saveTemplate() {
      const fieldList = this.$refs.corpField.fieldList.map((item) => {
        item.formId = 'corp-' + this.corpId
        return item
      })
      const removeIds = this.$refs.corpField.removeIds || []
      corpUserApi
        .saveField({ fieldList, removeIds }, false)
        .then((res) => {
          if (res.code === 0) {
            this.$nextTick(() => {
              this.$refs.corpField.getTemField()
            })
            this.showFieldList = false
            this.queryFormField()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
      // console.log('fieldList', this.$refs.fieldTem.fieldList)
    },
    handleCancel() {
      // this.$nextTick(() => {
      //   // this.$refs.corpField.getTemField()
      // })
      // this.queryFormField()
    },
    strToArr(item) {
      const str = item.listData
      if (str) {
        const arr = str.split(',')
        return arr
      } else {
        return []
      }
    },
    arrToStr(arr) {
      if (arr.length > 0) {
        const str = arr.join(',')
        return str
      } else {
        return ''
      }
    },
    openFieldList() {
      this.showFieldList = true
      this.formId = 'corp-' + this.corpId
    },
    // 选中字段改变
    fieldChange(e) {
      this.customField = []
      e.forEach((index) => {
        this.customField.push(this.fieldList[index])
      })
    },
    datasource(option, callback) {
      const filter = this.formatWhere(this.where)
      corpUserApi
        .page({
          corpId: this.corpId,
          ...filter,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 搜索 */
    reload() {
      this.selection = []
      const filter = this.formatWhere()
      if(this.$refs.tableMember){
        this.$refs.tableMember.reload({ page: 1, where: filter })
      }
    },
    formatWhere() {
      const filter = {}
      this.fieldList.forEach((item) => {
        if (item.type === '多项选择' || item.type === '标签') {
          if (this.where[item.fieldId] === undefined) {
            filter[item.fieldId] = []
          } else {
            filter[item.fieldId] = this.arrToStr(this.where[item.fieldId])
          }
        } else {
          filter[item.fieldId] = this.where[item.fieldId]
        }
      })
      return filter
    },
    /*  重置搜索 */
    reset() {
      this.where = {}
      this.queryFormField()
      this.reload()
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      this.$http
        .delete('/frm/corpUser/' + row.id)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.$message.success(res.data.msg)
            this.reload()
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的用户吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          this.$http
            .delete('/frm/corpUser/batch', {
              data: this.selection.map((d) => d.id)
            })
            .then((res) => {
              hide()
              if (res.data.code === 0) {
                this.$message.success(res.data.msg)
                this.reload()
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row || { corpId: this.corpId }
      this.showEdit = true
    },
    // 打开详情弹窗
    openDetail(row) {
      this.current = row || { corpId: this.corpId }
      this.showDetail = true
    },
    // 查询
    resetColums() {
      this.columns = this.formColums.concat([
        {
          title: '操作',
          width: 200,
          key: 'action',
          align: 'center',
          slots: { customRender: 'action' }
        }
      ])
      this.$nextTick(() => {
        // console.log('refs', this.$refs.tableMember)
        // this.$refs.tableMember.resetInitColumnList()
      })

      this.reload()
    },

    // 查询字段列表
    queryFieldList(arr) {
      this.where = {}
      this.addField = []
      this.customField = []
      this.fieldList = arr.filter((item) => {
        return {
          label: item.label,
          fieldId: item.fieldId,
          type: item.type,
          listData: item.listData
        }
      })
      const defaultField = ['name', 'phone', 'sex']
      defaultField.forEach((item) => {
        const index = this.fieldList.findIndex((value) => {
          return value.fieldId === item
        })
        if (index !== -1) {
          this.addField.push(index)
        }
      })
      this.addField.forEach((index) => {
        this.customField.push(this.fieldList[index])
      })
    },
    // 重置列表
    async queryFormField() {
      const that = this
      corpUserApi
        .fieldList({
          formId: 'corp-' + this.corpId,
          enable: true,
          sort: 'sortNumber'
        })
        .then((res) => {
          if (res.code === 0) {
            that.formColums = [
              {
                key: 'index',
                width: 48,
                customRender: ({ index }) =>
                  this.$refs.tableMember.tableIndex + index
              },
              {
                title: '状态',
                dataIndex: 'state',
                sorter: true
              }
            ]
            let data = []
            if (this.owner) {
              data = res.data
            } else {
              data = res.data.filter((item) => {
                return item.hide !== true
              })
            }
            for (let i = 0; i < data.length; i++) {
              if (
                data[i].type === '单张图片' ||
                data[i].type === '多张图片' ||
                data[i].type === '健康码' ||
                data[i].type === '涂写签名'
              ) {
                that.formColums.push({
                  title: data[i].label,
                  dataIndex: data[i].fieldId,
                  slots: { customRender: 'img' }
                })
              } else {
                that.formColums.push({
                  title: data[i].label,
                  dataIndex: data[i].fieldId,
                  sorter: true
                })
              }
            }
            this.queryFieldList(data)
            that.resetColums()
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    importDone() {
      this.queryFormField()
      this.reload()
    },
    columnschange() {
      
    }
  }
}
</script>

<style scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}
</style>
