<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="580"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 4 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 20 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 标题 -->
          <a-form-item label="标题:">
            <div class="ud-text-secondary">
              {{ data.title }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 副标题 -->
          <a-form-item label="副标题:">
            <div class="ud-text-secondary">
              {{ data.subtitle }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 背景图片 -->
          <a-form-item label="背景图片：">
            <div>
              <a-image
                alt="example"
                :width="100"
                :height="100"
                style="padding: 0px 10px 10px 0px"
                :src="data.bgPic"
              />
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- logo -->
          <a-form-item label="logo：">
            <div>
              <a-image
                alt="example"
                :width="100"
                :height="100"
                style="padding: 0px 10px 10px 0px"
                :src="data.logoPic"
              />
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 背景颜色 -->
          <a-form-item label="背景颜色:">
            <div class="ud-text-secondary">
              {{ data.colors }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'cardDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {}
  },
  watch: {
    data() {
      if (this.data) {
        // console.log('data111', this.data)
        // this.data.map((item) => {
        //   if (item.state === '0') {
        //     item.stateName = '不显示'
        //   } else if (item.state === '1') {
        //     item.stateName = '显示'
        //   }
        // })
      }
    }
  },
  mounted() {
    // this.init()
  },
  methods: {
    init() {
      //   if (this.data.questionType === 0) {
      //     this.data.map((item) => {
      //       item.questionTypeName = '单选'
      //     })
      //   } else if (this.data.questionType === 1) {
      //     this.data.map((item) => {
      //       item.questionTypeName = '多选'
      //     })
      //   }
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.answer {
  padding-left: 5%;
}
</style>
