<template>
  <div class="ud-body">
    <a-card :bordered="false">
      <!-- 搜索表单 -->
      <a-form
        ref="form"
        name="aForm"
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="24" :sm="24" :xs="24">
            <a-form-item
              label="状态:"
              name="state"
              :labelCol="{ span: 1.5, offset: 0 }"
            >
              <a-select v-model:value="where.state">
                <a-select-option
                  v-for="(item, index) in stateList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xs="24">
            <a-form-item
              label="姓名:"
              name="nickname"
              :labelCol="{ span: 1.5, offset: 0 }"
            >
              <a-input
                v-model:value="where.nickname"
                placeholder="请输入姓名"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xs="24">
            <a-form-item
              label="手机号:"
              name="phone"
              :labelCol="{ span: 1.5, offset: 0 }"
            >
              <a-input
                v-model:value="where.phone"
                placeholder="请输入手机号"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="info" @click="openFieldList">设置</a-button>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="tableCorpApprove"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        :where="where"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <!-- <template #corpPic="{ record }">
          <div class="image">
            <a-image
              :src="record.corpPic + '?width=120'"
              :width="120"
              :height="90"
            ></a-image>
          </div>
        </template> -->
        <template #avatar="{ record }">
          <div class="image">
            <a-image
              :src="record.applicantAvatar + '?width=120'"
              :width="120"
              :height="90"
            ></a-image>
          </div>
        </template>
        <template #state="{ record }">
          <span class="state-text" @click="openApplyDetail(record)">
            {{
              record.approveState === 0
                ? '审核中'
                : record.approveState === 1
                ? '已通过'
                : record.approveState === 2
                ? '已驳回'
                : record.approveState === 3
                ? '已过期'
                : ''
            }}
          </span>
        </template>
      </ud-pro-table>
    </a-card>
  </div>
  <!-- 模板字段设置 -->
  <a-modal
    :width="1300"
    v-model:visible="showFieldList"
    :confirm-loading="loading"
    @ok="handleOk"
  >
    <fromFieldList ref="corpApproveField" :formId="formId" @done="importDone" />
  </a-modal>

  <applyDetail
    v-model:visible="showApplyDetail"
    :data="current"
    @done="reload"
  ></applyDetail>
</template>
<script>
import * as corpApproveApi from '@/api/corp/corpApprove.js'
import applyDetail from './apply-detail.vue'
import fromFieldList from './field.vue'
import { createVNode } from 'vue'
import {
  // PlusOutlined,
  // MinusCircleOutlined,
  // ExportOutlined,
  DeleteOutlined,
  // UploadOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
export default {
  name: 'CorpUser',
  props: {
    // 是否打开弹窗
    corpId: Number
  },
  components: {
    DeleteOutlined,
    fromFieldList,
    applyDetail
  },
  data() {
    return {
      // datasource:[
      //   {
      //     index:1,
      //     id:1,
      //     state:0
      //   }
      // ],
      showApplyDetail: false,
      stateList: [
        {
          label: '未处理',
          value: 0
        },
        {
          label: '同意',
          value: 1
        },
        {
          label: '拒绝',
          value: 2
        },
        {
          label: '过期',
          value: 3
        }
      ],
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) =>
            this.$refs.tableCorpApprove.tableIndex + index
        },
        {
          title: '头像',
          // dataIndex: 'avatar',
          sorter: true,
          slots: {
            customRender: 'avatar'
          }
        },
        {
          title: '姓名',
          dataIndex: 'applicantName',
          sorter: true
        },
        {
          title: '电话',
          dataIndex: 'applicantPhone',
          sorter: true
        },
        {
          title: '内容',
          dataIndex: 'applicationMessage',
          sorter: true
        },
        {
          title: '处理时间',
          dataIndex: 'approveTime',
          sorter: true
        },
        {
          title: '状态',
          slots: {
            customRender: 'state'
          },
          sorter: true
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      formColums: [],
      // 当前编辑数据
      current: {},
      formId: 0,
      showFieldList: false, // 设置字段显示隐藏
      addField: []
    }
  },
  watch: {
    corpId() {
      if (this.corpId) {
        this.reload()
      }
    }
  },
  methods: {
    openApplyDetail(row) {
      this.current = row
      this.showApplyDetail = true
    },
    handleOk() {
      this.$refs.corpApproveField.save()
      this.showFieldList = false
    },
    openFieldList() {
      this.showFieldList = true
      this.formId = 'corp-' + this.corpId
    },
    datasource(option, callback) {
      corpApproveApi
        .page({
          ...this.where,
          corpId: this.corpId,
          type: 1,
          sort: 'createTime',
          order: 'desc',
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 搜索 */
    reload() {
      this.selection = []
      this.$refs.tableCorpApprove.reload({ page: 1, where: this.where })
    },
    /*  重置搜索 */
    reset() {
      this.where = { state: null }
      this.reload()
    },
    // resetColums() {
    //   this.columns = this.formColums.concat([
    //     {
    //       title: '操作',
    //       width: 200,
    //       align: 'center',
    //       slots: { customRender: 'action' }
    //     }
    //   ])
    //   this.reload()
    // },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的用户吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          corpApproveApi
            .deleteBatch(this.selection.map((d) => d.id))
            .then((res) => {
              hide()
              if (res.data.code === 0) {
                this.$message.success(res.data.msg)
                this.reload()
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}
.state-text {
  color: #0096d0;
  cursor: pointer;
}
</style>
