<!-- 用户导入弹窗 -->
<template>
  <a-modal :width="520" :footer="null" title="导入用户" :visible="visible" @update:visible="updateVisible">
    <a-spin :spinning="loading">
      <a-upload-dragger accept=".xls,.xlsx" :show-upload-list="false" :customRequest="doUpload"
        style="padding: 24px 0;margin-bottom: 16px;">
        <p class="ant-upload-drag-icon">
          <cloud-upload-outlined />
        </p>
        <p class="ant-upload-hint">将文件拖到此处，或点击上传</p>
      </a-upload-dragger>
    </a-spin>
    <div class="ud-text-center">
      <div>只能上传xls、xlsx文件！</div>
      <div>为了规范上传字段名称，系统预设了一些上传字段名称,如：</div>
      <div>姓名，性别，手机号，身份证号，邮箱；其中“手机号”为必须字段</div>
    </div>
  </a-modal>
</template>

<script>
import { CloudUploadOutlined } from '@ant-design/icons-vue'
import * as corpUserApi from '@/api/corp/corpUser.js'
export default {
  name: 'CorpUserImport',
  components: { CloudUploadOutlined },
  emits: ['done', 'update:visible'],
  props: {
    // 是否打开弹窗
    visible: Boolean,
    // 是否打开弹窗
    corpId: Number
  },
  data() {
    return {
      // 导入请求状态
      loading: false
    }
  },
  methods: {
    /* 上传 */
    doUpload({ file }) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', file)
      corpUserApi.importCorpUser(this.corpId, formData).then(res => {
        this.loading = false
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.updateVisible(false)
          this.$emit('done')
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.loading = false
        this.$message.error(e.message)
      })
      return false
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>

</style>
