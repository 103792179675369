<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="标题名称">
              <a-input
                v-model:value.trim="where.title"
                placeholder="请输入标题名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="副标题">
              <a-input
                v-model:value.trim="where.subtitle"
                placeholder="请输入副标题"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <!-- <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="标题名称:">
              <a-select
                allow-clear
                placeholder="请选择分类名称"
                v-model:value="where.categoryName"
              >
                <a-select-option
                  v-for="item in sortList"
                  :key="item.categoryId"
                  :value="item.categoryName"
                >
                  {{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <!-- <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="是否启用:">
              <a-select
                allow-clear
                placeholder="请选择是否启用"
                v-model:value="where.goodsStatus"
              >
                <a-select-option
                  v-for="item in goodsStatus"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="cardId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #img="{ record }">
          <img :src="record.bgPic" width="50" height="50" />
        </template>
        <template #logoPic="{ record }">
          <img :src="record.logoPic" width="50" height="50" />
        </template>
        <template #bgColor="{ record }">
          <div :style="{ background: record.colors }" class="bgColorBox"></div>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑 -->
    <CardEdit
      v-model:visible="showEdit"
      :corpId="corpId"
      :data="current"
      @done="reload"
    />
    <!-- 详情弹窗 -->
    <CardDetail v-model:visible="showDetail" :data="current || {}" />
  </div>
</template>

<script>
import * as corpCardApi from '@/api/corp/corpCard'
// import * as dictDataApi from '@/api/sys/dictData'
import CardEdit from './card-edit.vue'
import CardDetail from './card-detail.vue'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
export default {
  name: 'cardIndex',
  components: {
    PlusOutlined,
    DeleteOutlined,
    CardEdit,
    CardDetail
  },
  props: {
    corpId: Number
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          title: '标题',
          dataIndex: 'title'
        },
        {
          title: '副标题',
          dataIndex: 'subtitle'
        },
        {
          title: '背景图片',
          dataIndex: 'bgPic',
          sorter: true,
          slots: { customRender: 'img' }
        },
        {
          title: 'logo',
          dataIndex: 'logoPic',
          sorter: true,
          slots: { customRender: 'logoPic' }
        },
        {
          title: '背景颜色',
          dataIndex: 'colors',
          sorter: true,
          slots: { customRender: 'bgColor' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: '150px',
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 是否显示用户导入弹窗
      showImport: false,
      // 商品名称list
      sortList: [],
      // 状态字典项
      goodsStatus: []
      // corpId: 0
    }
  },
  watch: {
    corpId() {
      if (this.corpId) {
        this.reload()
      }
    }
  },
  methods: {
    datasource(option, callback) {
      corpCardApi
        .page({
          ...this.where,
          corpId: this.corpId,
          sort: 'createTime',
          order: 'desc',
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            // res.data.map((item) => {
            //   if (item.state === '0') {
            //     item.stateName = '不显示'
            //   } else if (item.state === '1') {
            //     item.stateName = '显示'
            //   }
            // })
            console.log('res210', res.data)

            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      corpCardApi
        .deleteById(row.cardId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项吗?',
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.cardId) }
          corpCardApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      // console.log('row325', row)
      // console.log('this.corpId326', this.corpId)
      this.current = row
      // this.corpId = row.corpId
      this.showEdit = true
    },
    /* 详情 */
    openDetail(row) {
      console.log('详情row', row)
      this.current = row
      this.showDetail = true
    }
    // 获取商品名称List
    // querysortList() {
    //   pointsCategoryApi
    //     .all()
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.sortList = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    // 是否显示
    // queryDataRoles() {
    //   dictDataApi
    //     .querydictdata('isShow')
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.goodsStatus = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // }
  }
}
</script>

<style scoped>
.bgColorBox {
  width: 35px;
  height: 35px;
  border-radius: 6px;
}
</style>
