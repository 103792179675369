<!-- 用户编辑弹窗 -->
<template>
  <a-modal
    :width="580"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改卡面管理' : '新建卡面管理'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="20" :sm="24" :xs="24">
          <a-form-item label="标题:" name="title">
            <a-input
              allow-clear
              placeholder="请输入标题"
              v-model:value="form.title"
            />
          </a-form-item>
          <a-form-item label="副标题:" name="subtitle">
            <a-input
              allow-clear
              :maxlength="18"
              placeholder="请输入副标题"
              v-model:value="form.subtitle"
            />
          </a-form-item>
          <a-form-item label="选择色系:" name="colors">
            <div class="colorStyle">
              <div
                v-for="(item, index) in colors"
                :key="index"
                @click="colorsClick(item)"
              >
                <div class="colorStyle-sub" :style="{ background: item.bg }">
                  <div class="icon" v-if="item.selectValue">
                    <CheckOutlined />
                  </div>
                </div>
              </div>
            </div>
          </a-form-item>
          <!-- <a-form-item label="状态:" name="state">
            <a-select
              allow-clear
              placeholder="请选择状态"
              v-model:value="form.state"
            >
              <a-select-option
                v-for="item in stateDict"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item> -->
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="背景图:" name="bgPic">
            <a-upload
              list-type="picture-card"
              v-model:file-list="imgList"
              @preview="handlePreview"
              :customRequest="({ file }) => uploadFile(file, 'bgPic')"
              :remove="removeFile"
            >
              <div v-if="imgList.length < 1">
                <PlusOutlined :style="{ fontSize: '26px' }" />
                <div class="ant-upload-text">背景图</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="LOGO:" name="logoPic">
            <a-upload
              list-type="picture-card"
              v-model:file-list="logoList"
              @preview="handlePreview"
              :customRequest="({ file }) => uploadFile(file, 'logoPic')"
              :remove="removeFile"
            >
              <div v-if="logoList.length < 1">
                <PlusOutlined :style="{ fontSize: '26px' }" />
                <div class="ant-upload-text"></div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import { PlusOutlined, CheckOutlined } from '@ant-design/icons-vue'
// import * as pointsCategoryApi from '@/api/jyyw/pointsCategory'
import * as corpCardApi from '@/api/corp/corpCard'
// import * as dictDataApi from '@/api/sys/dictData'
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }
export default {
  name: 'cardEdit',
  components: {
    PlusOutlined,
    CheckOutlined
  },
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    corpId: Number
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      imgList: [],
      logoList: [],
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      previewVisible: false,
      previewImage: '',
      // 状态字典项
      stateDict: [],
      colors: [
        {
          value: '1',
          bg: '#fa2209',
          selectValue: false
        },
        {
          value: '2',
          bg: '#ff547b',
          selectValue: false
        },
        {
          value: '3',
          bg: '#63be72',
          selectValue: false
        },
        {
          value: '4',
          bg: '#c3a769',
          selectValue: false
        },
        {
          value: '5',
          bg: '#2f2f34',
          selectValue: false
        },
        {
          value: '6',
          bg: '#884cff',
          selectValue: false
        },
        {
          value: '7',
          bg: '#65c4aa',
          selectValue: false
        },
        {
          value: '8',
          bg: '#fcc600',
          selectValue: false
        },
        {
          value: '9',
          bg: '#4a90e2',
          selectValue: false
        }
      ],
      colorValue: null
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        if (this.form.bgPic) {
          this.imgList[0] = {
            uid: '-5',
            name: 'image.png',
            status: 'done',
            url: this.form.bgPic
          }
        }
        if (this.form.logoPic) {
          this.logoList[0] = {
            uid: '-5',
            name: 'image.png',
            status: 'done',
            url: this.form.logoPic
          }
        }
        if (this.form.colors) {
          this.colors.map((x) => {
            if (x.bg === this.form.colors) {
              x.selectValue = true
            } else {
              x.selectValue = false
            }
          })
        }
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    colorsClick(item) {
      item.selectValue = !item.selectValue
      this.colors.map((x) => {
        if (item.value === x.value) {
          x.selectValue = true
        } else {
          x.selectValue = false
        }
      })
      this.form.colors = item.bg
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          console.log('this.form168', this.form)
          if (this.corpId) {
            this.form.corpId = this.corpId
            console.log('cardEdit208-----', this.form)
          }
          corpCardApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 删除图片
    removeFile(file) {
      console.log(file)
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.form.bgPic = ''
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    // 上传图片
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('corpId', this.getCorpId())
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'bgPic') {
              this.imgList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
              this.form.bgPic = res.data.location
            }
            if (name === 'logoPic') {
              this.logoList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
              this.form.logoPic = res.data.location
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    handlePreview(file) {
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    }
    // 状态字典项
    // queryDataStates() {
    //   dictDataApi
    //     .querydictdata('isShow')
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.stateDict = res.data
    //         this.stateDict.map((item) => {
    //           item.dictDataCode = parseInt(item.dictDataCode)
    //         })
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // }
  }
}
</script>

<style lang="less" scoped>
.colorStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .colorStyle-sub {
    width: 35px;
    height: 35px;
    border-radius: 6px;
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    // background: #40a9ff;
    .icon {
      position: absolute;
      color: #fff;
    }
  }
}
</style>
